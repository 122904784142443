<template>
  <button
    v-bind="$attrs"
    class="inline-flex items-center justify-center py-2 space-x-2 text-white rounded outline-none bg-cadmium focus:ring-1 focus:ring-cadmium focus:ring-offset-2"
    :type="type"
    :disabled="loading"
  >
    <loading-animation-icon
      class="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
      v-if="loading"
    />
    <slot></slot>
  </button>
</template>

<script>
import LoadingAnimationIcon from "@/components/icons/LoadingAnimationIcon.vue";

export default {
  props: {
    type: {
      type: String,
      default: "button",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingAnimationIcon,
  },
};
</script>

<style></style>
