import { createApp } from "vue/dist/vue.esm-bundler";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
// import vue3GoogleLogin from "vue3-google-login";
import "@/assets/index.css";
import i18n from "@/i18n";

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  // .use(vue3GoogleLogin, {
  //   scopes: ["profile", "email"],
  //   clientId:
  //     "1032425861844-47084uts6e0cd0q86kiltqg2bkhbqtp7.apps.googleusercontent.com",
  // })
  .directive("click-outside", clickOutside)
  .mount("#app");
