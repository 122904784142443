import { mainAxios, customAxios } from "../http-common";
class userDataService {
  login(data) {
    return mainAxios.post("/authentication/local/login", data);
  }

  userme() {
    return customAxios.get("/me/");
  }

  changePassword(data) {
    return customAxios.post("/me/security/change-password", data);
  }

  resetPassword(data) {
    return customAxios.post("/auth/password-reset/request", data);
  }

  userLogout() {
    return customAxios.post("/me/security/logout", {});
  }

  userAthletes(guid) {
    return customAxios.get("/app/athletes/" + guid);
  }

  updateProfile(data) {
    return customAxios.post("/me/", data);
  }
}
export default new userDataService();
