<template>
  <button
    type="button"
    class="px-6 py-1 mr-2 text-center text-white capitalize rounded sm:mr-8 bg-cadmium shrink"
    v-if="$route.path !== '/newmatch'"
  >
    {{ $t("new_match") }}
  </button>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["user", "newMatchPermission"]),
  },
};
</script>

<style></style>
