<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    :stroke-width="strokeWidth"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
    />
  </svg>
</template>

<script>
export default {
  props: {
    strokeWidth: {
      type: Number,
      default: 2,
    },
  },
};
</script>

<style></style>
