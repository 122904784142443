import { createStore } from "vuex";
import userDataService from "@/services/userDataServices";
import matchService from "@/services/matchServices";
import configurationServices from "@/services/configurationServices";
import moment from "moment";
import compactLodash from "lodash/compact";
import sortByLodash from "lodash/sortBy";
import cloneDeepLodash from "lodash/cloneDeep";
import findIndexLodash from "lodash/findIndex";
import { FOIL, EPEE, SABRE } from "@/enums/weaponTypes";
import { LEFT, RIGHT } from "@/enums/positionTypes";
import { PORFESSIONAL, FREE } from "@/enums/licenceTypes";
import { getMatch as fetchMatch } from "@/composable/matchApis";
export default createStore({
  state: {
    user: {
      name: "",
      email: "",
      guid: "",
      birthDate: "",
      sex: "",
      nationality: "",
      preferredWeapon: "",
      laterality: "",
      licenceType: FREE,
      licenceValidTo: moment().format(),
    },

    newMatchPermission: false,

    match1: {
      athleteLeft: "",
      athleteRight: "",
      weaponType: "",
      matchtype: "",
      datetime: "",
    },

    matches: [],
    opponents: [],

    newmatch: {
      scoreLeft: 0,
      scoreRight: 0,
      athleteLeftGuid: null,
      athleteRightGuid: null,
      weaponType: "",
      matchTypeId: null,
      matchDateTime: "",
      sequences: new Array(),
      athleteLeftObj: {
        name: "",
        guid: null,
        disable: true,
        droplist: false,
      },
      athleteRightObj: {
        name: "",
        guid: null,
        disable: false,
        droplist: false,
      },
    },

    actionTypes: [],
    matchTypes: [],

    hideFirst: false,
  },
  getters: {
    matchesCount: (state) => state.matches.length,

    matchesWinCount(state) {
      return state.matches.filter((e) => {
        if (state.user.guid == e.athleteLeftGuid) {
          if (e.scoreLeft > e.scoreRight) {
            return e;
          }
        } else {
          if (e.scoreLeft < e.scoreRight) {
            return e;
          }
        }
      }).length;
    },

    matchesLostCount(state) {
      return state.matches.filter((e) => {
        if (state.user.guid == e.athleteLeftGuid) {
          if (e.scoreLeft < e.scoreRight) {
            return e;
          }
        } else {
          if (e.scoreLeft > e.scoreRight) {
            return e;
          }
        }
      }).length;
    },

    matchesTypeNameById: (state) => (id) => {
      return state.matchTypes.find((e) => e.id == id);
    },

    actionTypeIdByName: (state) => (name) => {
      return state.actionTypes.find((e) => e.codeName == name);
    },

    actionTypeNameById: (state) => (id) => {
      return state.actionTypes.find((e) => e.id == id);
    },

    actionTypeByCategory:
      (state) =>
      (cat, text = null) => {
        return state.actionTypes.filter((e) => {
          if (e.category == cat) {
            if (text && text.length > 0) {
              let search = e.codeName.replaceAll("_", " ").search(text);
              if (search >= 0) {
                return e;
              }
            } else {
              return e;
            }
          }
        });
      },

    actionTypeCategories: (state) => {
      return compactLodash([
        ...new Set(state.actionTypes.map((obj) => obj.category)),
      ]);
    },

    getLastNameDate(state) {
      let sortedmatches = sortByLodash(state.matches, [
        function (o) {
          return o.synchronizedAt;
        },
      ]);
      return moment(
        sortedmatches[sortedmatches.length - 1].synchronizedAt
      ).format("DD.MM.YYYY");
    },

    getEditMatchByGuid: (state) => async (guid) => {
      let match = state.matches.find((e) => e.guid == guid);
      let leftop = {};
      if (match != undefined) {
        if (match.athleteLeftGuid == state.user.guid) {
          leftop = cloneDeepLodash(state.user);
        } else {
          leftop = state.opponents.find((o) => o.guid == match.athleteLeftGuid);
        }

        if (leftop != undefined) {
          match.athleteLeftObj = {
            name: leftop.name,
            guid: match.athleteLeftGuid,
            disable: true,
            droplist: false,
          };
        }
        let rightop = {};
        if (match.athleteRightGuid == state.user.guid) {
          rightop = cloneDeepLodash(state.user);
        } else {
          rightop = await state.opponents.find(
            (o) => o.guid == match.athleteRightGuid
          );
        }
        if (rightop != undefined) {
          match.athleteRightObj = {
            name: rightop.name,
            guid: rightop.guid,
            disable: false,
            droplist: false,
          };
        }
        return match;
      } else {
        return null;
      }
    },

    changeCalculateAction: () => (calactions) => {
      let actions = [];
      for (let index = 0; index < calactions.length; index++) {
        let element = calactions[index];
        for (let i = 0; i < element.length; i++) {
          let ele = element[i];
          actions.push(ele);
        }
      }
      return actions;
    },

    changeActionToCA: (state, getters) => (actions) => {
      let calactions = [];
      for (let index = 0; index < actions.length; index++) {
        const element = actions[index];
        if (getters.calActionValid(element.actionTypeId)) {
          if (calactions.length > 0) {
            if (
              calactions[calactions.length - 1][0].athleteSide ==
              element.athleteSide
            ) {
              calactions[calactions.length - 1].push(element);
            } else {
              calactions.push([element]);
            }
          } else {
            calactions.push([element]);
          }
        }
      }
      return calactions;
    },

    calActionValid: (state, getters) => (id) => {
      if (
        id != getters.actionTypeIdByName("red_card")?.id &&
        id != getters.actionTypeIdByName("rear_limit")?.id &&
        id != getters.actionTypeIdByName("body_part")?.id &&
        id != getters.actionTypeIdByName("double_hit")?.id &&
        id != getters.actionTypeIdByName("simultane")?.id
      ) {
        return true;
      } else {
        return false;
      }
    },

    onlyActionValid: (state, getters) => (id) => {
      if (
        id != getters.actionTypeIdByName("red_card")?.id &&
        id != getters.actionTypeIdByName("rear_limit")?.id &&
        id != getters.actionTypeIdByName("double_hit")?.id &&
        id != getters.actionTypeIdByName("simultane")?.id
      ) {
        return true;
      } else {
        return false;
      }
    },

    calActionInValid: (state, getters) => (id) => {
      if (
        id == getters.actionTypeIdByName("red_card")?.id ||
        id == getters.actionTypeIdByName("rear_limit")?.id ||
        id == getters.actionTypeIdByName("body_part")?.id ||
        id == getters.actionTypeIdByName("double_hit")?.id ||
        id == getters.actionTypeIdByName("simultane")?.id
      ) {
        return true;
      } else {
        return false;
      }
    },

    changeHideFirst: (state) => (value) => {
      state.hideFirst = value;
    },
  },
  mutations: {
    setUserAthletes(state, payload) {
      state.user.name = payload.name;
      state.user.birthDate = moment(payload.birthDate).format("YYYY-MM-DD");
      state.user.sex = payload.sex;
      state.user.nationality = payload.nationality;
      if (
        payload.preferredWeapon == FOIL ||
        payload.preferredWeapon == EPEE ||
        payload.preferredWeapon == SABRE
      ) {
        state.user.preferredWeapon = payload.preferredWeapon;
      } else {
        state.user.preferredWeapon = "unknown";
      }

      if (payload.laterality == RIGHT || payload.laterality == LEFT) {
        state.user.laterality = payload.laterality;
      } else {
        state.user.laterality = "unknown";
      }
    },

    setUserMe(state, payload) {
      state.user.email = payload.email;
      state.user.guid = payload.athleteGuid;
      state.user.licenceType = payload.licenceType;
      state.user.licenceValidTo = payload.licenceValidTo;
      if (state.user.licenceType == PORFESSIONAL) {
        state.newMatchPermission = true;
      } else {
        state.newMatchPermission = false;
      }
    },

    setMatch(state, payload) {
      state.match1.athleteLeft = payload.athleteLeft;
      state.match1.datetime = payload.datetime;
      state.match1.athleteRight = payload.athleteRight;
      if (
        payload.weaponType == FOIL ||
        payload.weaponType == EPEE ||
        payload.weaponType == SABRE
      ) {
        state.match1.weaponType = payload.weaponType;
      } else {
        state.match1.weaponType = FOIL;
      }

      if (payload.matchtype == "23" || payload.matchtype == "24") {
        state.match1.matchtype = payload.matchtype;
      } else {
        state.match1.matchtype = "unknown";
      }
      if (state.user.licenceType == FREE && state.matches.length > 9) {
        state.newMatchPermission = false;
      } else {
        state.newMatchPermission = true;
      }
    },

    setNewMatch(state, payload) {
      state.newmatch.athleteLeftGuid = payload.athleteLeftGuid;
      state.newmatch.matchDateTime = payload.matchDateTime;
      state.newmatch.athleteRightGuid = payload.athleteRightGuid;
      if (
        payload.weaponType == FOIL ||
        payload.weaponType == EPEE ||
        payload.weaponType == SABRE
      ) {
        state.newmatch.weaponType = payload.weaponType;
      } else {
        state.newmatch.weaponType = "unknown";
      }

      state.newmatch.matchTypeId = payload.matchTypeId;
      state.newmatch.athleteLeftObj = payload.athleteLeftObj;
      state.newmatch.athleteRightObj = payload.athleteRightObj;
      state.newmatch.guid = payload.guid;
      state.newmatch.phaseType = payload.phaseType;
    },

    resetNewMatch(state) {
      state.newmatch.scoreLeft = 0;
      state.newmatch.scoreRight = 0;
      state.newmatch.athleteLeftGuid = null;
      state.newmatch.athleteRightGuid = null;
      state.newmatch.weaponType = "";
      state.newmatch.matchTypeId = null;
      state.newmatch.matchDateTime = "";
      state.newmatch.sequences = [];
      state.newmatch.athleteLeftObj.name = "";
      state.newmatch.athleteLeftObj.guid = null;
      state.newmatch.athleteLeftObj.disable = true;
      state.newmatch.athleteLeftObj.droplist = false;
      state.newmatch.athleteRightObj.name = "";
      state.newmatch.athleteRightObj.guid = null;
      state.newmatch.athleteRightObj.disable = false;
      state.newmatch.athleteRightObj.droplist = false;
      state.planchValue = null;
      state.planchNote = null;
    },

    setMatches(state, payload) {
      state.matches = payload;
      if (state.user.licenceType == FREE && state.matches.length > 9) {
        state.newMatchPermission = false;
      } else {
        state.newMatchPermission = true;
      }
    },

    setOpponent(state, payload) {
      let filteropp = state.opponents.filter((opp) => {
        return payload.guid == opp.guid || state.user.guid == payload.guid;
      });
      if (filteropp.length == 0) {
        state.opponents.push(payload);
      }
    },

    addSequence(state, payload) {
      state.newmatch.sequences.push(payload);
      state.newmatch.scoreLeft += payload.relativePointsLeft;
      state.newmatch.scoreRight += payload.relativePointsRight;
    },

    editSequence(state, payload) {
      let oldseq = state.newmatch.sequences.pop();
      state.newmatch.scoreLeft -= oldseq.relativePointsLeft;
      state.newmatch.scoreRight -= oldseq.relativePointsRight;
      state.newmatch.sequences.push(payload);
      state.newmatch.scoreLeft += payload.relativePointsLeft;
      state.newmatch.scoreRight += payload.relativePointsRight;
    },

    removeSequence(state) {
      state.newmatch.sequences.pop();
    },

    setActionTypes(state, payload) {
      state.actionTypes = payload;
    },

    setMatchTypes(state, payload) {
      state.matchTypes = payload;
    },

    setFreshMatch(state, payload) {
      state.matches.unshift(payload);
      if (state.user.licenceType == FREE && state.matches.length > 9) {
        state.newMatchPermission = false;
      } else {
        state.newMatchPermission = true;
      }
    },

    removeMatch(state, payload) {
      let matchindex = findIndexLodash(state.matches, function (o) {
        return o.guid == payload;
      });
      state.matches.splice(matchindex, 1);
      if (state.user.licenceType == FREE && state.matches.length > 9) {
        state.newMatchPermission = false;
      } else {
        state.newMatchPermission = true;
      }
    },

    setEditMatch(state, payload) {
      let matchIndex = findIndexLodash(
        state.matches,
        (e) => e.guid == payload.guid
      );
      if (matchIndex >= 0) {
        state.matches[matchIndex] = payload;
      }
    },
  },
  actions: {
    async getUseAthletes(state) {
      await userDataService
        .userAthletes(state.state.user.guid)
        .then((response) => {
          state.commit("setUserAthletes", response.data);
        })
        .catch((err) => (this.error = err.response.data));
    },

    async getUserMe(state) {
      await userDataService
        .userme()
        .then((response) => {
          if ("email" in response.data) {
            state.commit("setUserMe", response.data);
          } else {
            this.loaded = false;
          }
        })
        .catch((err) => (this.error = err.response.data));
    },

    async getMatch(state) {
      if (state) {
        state.commit("setMatch", state.state.match1);
      }
      /* await userDataService
        .userAthletes(state.state.user.guid)
        .then((response) => {
          state.commit("setUserAthletes", response.data);
        })
        .catch((err) => (this.error = err.response.data));*/
    },

    async getUserMatches(state) {
      await matchService
        .matches({
          group: "matchDateTime",
        })
        .then((response) => {
          if (response.data) {
            state.commit(
              "setMatches",
              response.data.sort(function (a, b) {
                return new Date(b.matchDateTime) - new Date(a.matchDateTime);
              })
            );
          }
        });
    },

    async getOpponent(state, guid) {
      await userDataService.userAthletes(guid).then((response) => {
        if (response.status == 200 || response.status == 204) {
          state.commit("setOpponent", response.data);
        }
      });
    },

    async getConfigurationPersonal(state) {
      await configurationServices.configurationPersonal().then((response) => {
        if (response.status == 200) {
          state.commit("setActionTypes", response.data.actionTypes);
          state.commit("setMatchTypes", response.data.matchTypes);
        }
      });
    },

    async getFetchMatch({ state, commit, dispatch }, guid) {
      await fetchMatch(guid)
        .then(async (response) => {
          if (response.status == 200) {
            if (response.data.athleteLeftGuid == state.user.guid) {
              await dispatch("getOpponent", response.data.athleteRightGuid);
            } else {
              await dispatch("getOpponent", response.data.athleteLeftGuid);
            }
            commit("setMatches", [...state.matches, response.data]);
          }
        })
        .catch(() => {});
    },
  },
});
