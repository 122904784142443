import { customAxios } from "@/http-common";
import { LEFT } from "@/enums/positionTypes";
class matchService {
  matches(data) {
    return customAxios.get("/app/matches", {
      params: data,
    });
  }

  getAthleteDetail(guid) {
    return customAxios.get(`/app/athletes/${guid}`);
  }

  getMatch(guid) {
    return customAxios.get("/app/matches/" + guid);
  }

  addMatch(guid, data) {
    return customAxios.put(`app/matches/` + guid, data);
  }

  getMatchOverview(match) {
    let data = {
      redSignLeft: 0,
      yellowSignLeft: 0,
      redCardLeft: 0,
      rareLimitLeft: 0,
      simultane: 0,
      double_hit: 0,
      redSignRight: 0,
      yellowSignRight: 0,
      redCardRight: 0,
      rareLimitRight: 0,
      leftHitZones: [],
      rightHitZones: [],
    };
    if (match.sequences.length > 0) {
      match.sequences.map((e) => {
        if (e.actions.length > 0) {
          e.actions.map((act) => {
            switch (act.actionTypeId) {
              case 88:
                data.simultane++;
                break;
              case 89:
                data.double_hit++;
                break;
              case 90:
                if (act.athleteSide == LEFT) {
                  data.redCardLeft++;
                } else {
                  data.redCardRight++;
                }
                break;
              case 91:
                if (act.athleteSide == LEFT) {
                  data.rareLimitLeft++;
                } else {
                  data.rareLimitRight++;
                }
                break;
              case 94:
                if (act.athleteSide == LEFT) {
                  data.redSignLeft++;
                } else {
                  data.redSignRight++;
                }
                break;
              case 95:
                if (act.athleteSide == LEFT) {
                  data.yellowSignLeft++;
                } else {
                  data.yellowSignRight++;
                }
                break;

              default:
                break;
            }
          });
        }
        if (e.hitZone != null) {
          if (e.relativePointsLeft > e.relativePointsRight) {
            data.leftHitZones.push(e.hitZone);
          }
          if (e.relativePointsLeft < e.relativePointsRight) {
            data.rightHitZones.push(e.hitZone);
          }
        }
      });
    }
    return data;
  }

  deleteMatch(guid) {
    return customAxios.delete(`/app/matches/${guid}`);
  }
}
export default new matchService();
