<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-30 overflow-y-auto bg-gray-300/50">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="relative inline-block w-full max-w-2xl px-16 py-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >
              <DialogTitle
                as="h3"
                class="mt-8 mb-12 text-4xl font-semibold leading-6 text-center capitalize text-yelloworange"
              >
                {{ $t("upgrade_a_licence") }}
              </DialogTitle>
              <div class="flex flex-wrap items-center justify-around">
                <div class="my-2">
                  <p class="text-lg text-center text-gray-500">
                    {{ $t("upgrade_a_licence_description") }}
                  </p>
                </div>
                <a :href="playStoreLink">
                  <img
                    :src="GooglePlayBadge"
                    alt="google-play-badge.png"
                    class="object-cover"
                    fetchpriority="high"
                  />
                </a>
                <a :href="AppleStoreLink">
                  <img
                    :src="AppleStoreBadge"
                    alt="apple-store-badge"
                    class="object-cover"
                    fetchpriority="high"
                  />
                </a>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import AppleStoreBadge from "@/assets/images/apple-store-badge.jpg";
import GooglePlayBadge from "@/assets/images/google-play-badge.png";
import {
  Dialog,
  TransitionRoot,
  TransitionChild,
  DialogTitle,
  DialogOverlay,
} from "@headlessui/vue";

export default {
  setup() {
    const isOpen = ref(false);
    const playStoreLink = ref(
      "https://play.google.com/store/apps/details?id=cz.riposte.planchettracker&hl=en&gl=US"
    );
    const AppleStoreLink = ref(
      "https://apps.apple.com/tt/app/planchet-tracker/id1490574812"
    );

    function openModal() {
      isOpen.value = true;
    }

    function closeModal() {
      isOpen.value = false;
    }

    return {
      isOpen,
      openModal,
      closeModal,
      playStoreLink,
      AppleStoreLink,
      GooglePlayBadge,
      AppleStoreBadge,
    };
  },
  components: {
    Dialog,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
    DialogOverlay,
  },
};
</script>
