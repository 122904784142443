import axios from "axios";

axios.defaults.headers["Content-Type"] = "application/json";
// axios.defaults.headers["Access-Control-Allow-Origin"] = "*";

const mainAxios = axios.create({
  baseURL: "https://dev.planchet-tracker.bumbu.designeo.cz/api/v1",
});

const customAxios = axios.create({
  baseURL: "https://dev.planchet-tracker.bumbu.designeo.cz/api/v1",
  headers: {
    Authorization: "TOKEN " + localStorage.getItem("token"),
  },
});

export { mainAxios, customAxios };
