import { customAxios } from "@/http-common";

//Api
export function addMatch(guid, data) {
  return customAxios.put(`app/matches/` + guid, data);
}

export function editMatch(guid, data) {
  return customAxios.put(`app/matches/` + guid, data);
}

export function getMatch(guid) {
  return customAxios.get(`app/matches/` + guid);
}
